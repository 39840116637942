import { inject, Injectable } from '@angular/core'
import { ParamState } from '../data-access/state/param.state'
import { ParamHttpService } from '../data-access/http/param-http.service'
import { Observable, tap } from 'rxjs'
import { ParamDto } from '../data-access/http/dto/param-dto'
import { SaveParamDto } from '../data-access/http/dto/save-param.dto'
import { ParamCodeEnum } from '../models/enums/param-code.enum'

@Injectable({ providedIn: 'root' })
export class ParamDomainService {
    paramState = inject(ParamState)
    paramHttpService = inject(ParamHttpService)

    // Selectors
    params$ = this.paramState.store.select(ParamState.getParams)

    params(): ParamDto[] {
        return this.paramState.store.selectSnapshot(ParamState.getParams)
    }
    getParamByCode(codeParam: ParamCodeEnum): ParamDto {
        return this.paramState.store.selectSnapshot(ParamState.getParamByCode(codeParam))
    }

    // Actions
    loadParams(): Observable<ParamDto[]> {
        return this.paramHttpService.getParams().pipe(tap((params) => this.paramState.store.update(() => params)))
    }

    saveParams(...params: SaveParamDto[]): Observable<ParamDto[]> {
        const paramsToSave = []
        for (const param of params) {
            const { codeParam, valeurParam } = param
            const currentParam = this.paramState.store.selectSnapshot(ParamState.getParamByCode(codeParam)) || {}
            paramsToSave.push({ ...currentParam, codeParam, valeurParam })
        }
        return this.paramHttpService.saveParams(paramsToSave).pipe(
            tap((params) =>
                this.paramState.store.update((state) => {
                    const newState = [...state]
                    for (const param of params) {
                        const paramIndex = state.findIndex((paramDto) => paramDto.id === param.id)
                        if (paramIndex >= 0) {
                            newState[paramIndex] = param
                        } else {
                            newState.push(param)
                        }
                    }
                    return newState
                }),
            ),
        )
    }
}
