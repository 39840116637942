import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap'
import { OAuthService } from 'angular-oauth2-oidc'
import { authConfig } from './core/configuration/auth.config'
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks'
import { AsyncPipe, NgIf, registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import { ChantierDomainService } from './shared/domain/chantier.domain'
import { map, Observable } from 'rxjs'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NgIf, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    private configModal: NgbModalConfig
    private configTooltip: NgbTooltipConfig
    private oauthService: OAuthService
    private readonly chantierDomainService: ChantierDomainService
    theme$: Observable<string>

    constructor() {
        this.configModal = inject(NgbModalConfig)
        this.configTooltip = inject(NgbTooltipConfig)
        this.oauthService = inject(OAuthService)
        this.chantierDomainService= inject(ChantierDomainService)

        this.theme$ = this.chantierDomainService.selectedChantierIsIris$.pipe(
            map((isIris: boolean) => (isIris ? 'iris' : 'chantier')),
        )

        this.configureModal()
        this.configureTooltip()
        this.configureAuthService()
        registerLocaleData(localeFr)
    }

    ngOnInit(): void {
        this.removeStartupLoader()
    }

    removeStartupLoader(): void {
        const startupLoader: HTMLElement | null = document.getElementById('startup-loader')
        if (startupLoader) {
            startupLoader.remove()
        }
    }

    private configureTooltip(): void {
        this.configTooltip.container = 'body'
        this.configTooltip.openDelay = 600
    }

    private configureModal(): void {
        this.configModal.centered = true
        this.configModal.backdrop = 'static'
    }

    private configureAuthService(): void {
        this.oauthService.configure(authConfig)
        this.oauthService.tokenValidationHandler = new JwksValidationHandler()
        this.oauthService.setStorage(localStorage)
    }
}
