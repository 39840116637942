import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core'
import { Interimaire } from '../../../../shared/models/interimaire'
import { NgbModal, NgbPagination } from '@ng-bootstrap/ng-bootstrap'
import { InterimaireHttpService } from '../../../../shared/data-access/http/interimaire-http.service'
import { ToastrService } from 'ngx-toastr'
import { HttpResponse } from '@angular/common/http'
import { downloadPdfFile } from '../../../../shared/utils/utils'
import { NgFor, NgIf, SlicePipe } from '@angular/common'
import { ChantierDomainService } from '../../../../shared/domain/chantier.domain'
import { DisplayDatePipe } from '../../../../shared/pipes/display-date.pipe'

@Component({
    selector: 'app-donnes-interimaires-portlet-body',
    templateUrl: './donnes-interimaires-portlet-body.component.html',
    styleUrls: ['./donnes-interimaires-portlet-body.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgbPagination, SlicePipe, DisplayDatePipe],
})
export class DonnesInterimairesPortletBodyComponent implements OnInit {
    @Input() data: Interimaire[]
    @ViewChild('donneesInterimairesModale') donneesInterimairesModale: ElementRef
    dateDerniereArrivee: Date = undefined

    Arr = Array
    chantierCode: string
    interimairesCurrentPage: Interimaire[] = undefined
    currentPageNumber: number = 0
    minPageSize: number = 10
    pageSize: number = this.minPageSize
    totalPageNumber: number = undefined
    totalInterimairesNumber: number = undefined
    interimairesPageIsLoading = false
    downloadingCsvFile: boolean = false

    constructor(
        @Inject('DATA') private dataInjected: string,
        public modalService: NgbModal,
        public interimaireService: InterimaireHttpService,
        public toastr: ToastrService,
        private chantierDomainService: ChantierDomainService,
    ) {}

    ngOnInit(): void {
        this.data = this.data || JSON.parse(this.dataInjected)
        this.data = this.data.map((interimaire) => new Interimaire(interimaire))

        this.chantierCode = this.chantierDomainService.selectedChantierCode()

        this.dateDerniereArrivee = this.data[0]?.dateFinMissionActuelle
    }

    openDonneesInterimairesModale(): void {
        this.modalService.open(this.donneesInterimairesModale, { fullscreen: true })

        // Détermination du nombre d'entrées par page, suivant la taille du conteneur interimaireTableContainer
        // La pagination sera un multiple de 5, avec un minimum de d'entrées déterminé par minPageSize
        const interimaireTableContainerHeight = document.getElementById('interimaireTableContainer')?.clientHeight
        const tempPageSize = interimaireTableContainerHeight
            ? Math.round((interimaireTableContainerHeight * 0.025 - 10) / 5) * 5
            : 0
        this.pageSize = tempPageSize < this.minPageSize ? this.minPageSize : tempPageSize

        this.loadInterimairesPage()
    }

    loadInterimairesPage(): void {
        this.interimairesPageIsLoading = true
        this.interimaireService.findByCodeChantier(this.chantierCode, this.currentPageNumber, this.pageSize).subscribe({
            next: (interimairesPage) => {
                if (!this.totalInterimairesNumber) {
                    this.totalInterimairesNumber = interimairesPage.totalElements
                }

                if (!this.totalPageNumber) {
                    this.totalPageNumber = interimairesPage.totalPages
                }

                this.interimairesCurrentPage = interimairesPage.content.map((int: Interimaire) => new Interimaire(int))
                this.interimairesPageIsLoading = false
            },
            error: (err) => {
                this.toastr.error('Une erreur est survenue lors de la recherche des intérimaires du chantier')
                console.error(err)
                this.interimairesPageIsLoading = false
            },
        })
    }

    onPageChange($event: number): void {
        if ($event - 1 !== this.currentPageNumber) {
            this.currentPageNumber = $event - 1
            this.loadInterimairesPage()
        }
    }

    downloadCsvFile(): void {
        this.downloadingCsvFile = true
        this.interimaireService.buildInterimairesChantierCsv(this.chantierCode).subscribe({
            next: (response: HttpResponse<Blob>) => {
                this.downloadingCsvFile = false
                downloadPdfFile(response)
                this.toastr.success('Génération du CSV terminée')
            },
            error: (err) => {
                this.toastr.error('Une erreur est survenue lors du téléchargement du fichier')
                console.error(err)
                this.downloadingCsvFile = false
            },
        })
    }

    dismissDonneesInterimairesModale(): void {
        this.modalService.dismissAll()
        this.interimairesCurrentPage = undefined
        this.currentPageNumber = 0
        this.totalPageNumber = undefined
        this.totalInterimairesNumber = undefined
        this.interimairesPageIsLoading = false
    }

    getPosteQualif(interimaire: Interimaire): string {
        const poste = interimaire?.emploi
        const qualif = interimaire?.qualification

        if (poste && qualif) {
            return qualif + ' - ' + poste
        }

        if (poste) {
            return poste
        }

        if (qualif) {
            return qualif
        }

        return '-'
    }
}
