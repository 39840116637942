import { inject, Injectable } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class VersionService {
    private static endpoint = `${environment.baseUrl}/version`
    private readonly http = inject(HttpClient)
    getVersionBackend(): Observable<string> {
        return this.http.get(VersionService.endpoint, { responseType: 'text' })
    }
}
