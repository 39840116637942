import { Injectable } from '@angular/core'
import { Store } from '../../../shared/models/store'
import { ChantierDTO } from '../../../shared/data-access/http/dto/chantier-dto'

export interface CoreModuleStateModel {
    searchedChantiers: ChantierDTO[]
    selectedChantier: ChantierDTO
    isChantierParametre: boolean
    chantierAdminEmails: string[]
}

@Injectable({ providedIn: 'root' })
export class CoreModuleState {
    store = new Store<CoreModuleStateModel>({
        searchedChantiers: [],
        selectedChantier: null,
        isChantierParametre: false,
        chantierAdminEmails: [],
    })

    static readonly getSearchedChantiers = (state: CoreModuleStateModel): ChantierDTO[] => state.searchedChantiers
    static readonly getChantierAdminEmails = (state: CoreModuleStateModel): string[] => state.chantierAdminEmails
    static readonly getIsChantierParametre = (state: CoreModuleStateModel): boolean => state.isChantierParametre
    static readonly getSelectedChantier = (state: CoreModuleStateModel): ChantierDTO => state.selectedChantier
}
