<div class="container py-0 px-0 h-100">
    <div>
        <ng-container *ngIf="status === applicationStatusEnum.OK && dataSuccess?.livraisons?.length">
            <div class="row pb-3">
                <div class="col-8 text-truncate" title="Date de dernière arrivée">Date de dernière demande</div>
                <div class="col-4">
                    <div class="row ms-1">
                        <div class="fw-semibold text-nowrap ps-0 fs-6 text-end">
                            {{
                                dataSuccess.dateDerniereDemande
                                    ? (dataSuccess.dateDerniereDemande | date: "shortDate")
                                    : "-"
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ps-2 pb-0 table-responsive">
                <table class="table table-borderless table-hover">
                    <caption class="visually-hidden">
                        Planning livraison
                    </caption>
                    <thead class="border-bottom border-primary">
                        <tr>
                            <th class="portlet-table-cell lg-red" scope="col" title="Date">Date</th>
                            <th class="portlet-table-cell lg-red" scope="col" title="Nom">Nombre de livraison</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let livraison of dataSuccess.livraisons | slice: 0 : 4">
                            <td
                                class="portlet-table-cell text-truncate"
                                [title]="livraison.dateLivraison | displayDate"
                            >
                                {{ livraison.dateLivraison | displayDate }}
                            </td>
                            <td class="fw-normal portlet-table-cell text-truncate" [title]="livraison.nbLivraison">
                                {{ livraison.nbLivraison }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>

        <div *ngIf="status === applicationStatusEnum.KO" class="text-center">
            <ng-container *ngIf="dataError?.erreur">
                <p class="mt-4">{{ dataError.erreur }}</p>
            </ng-container>
            <ng-container *ngIf="!dataError?.erreur">
                <p>Attention, un problème est survenu à la lecture du Google Sheet de planning :</p>
                <p>
                    1) Avez-vous partagé le fichier Google Sheet avec l'utilisateur technique
                    service-account&#64;dsi-admin-237215.iam.gserviceaccount.com ?
                </p>
                <p>
                    2) Avez-vous ajouté des remarques ou des commentaires dans le fichier Google Sheet ? Si oui,
                    veuillez les enlever.
                </p>
                <p>
                    Si après avoir fait les vérifications précédentes le problème est persistant, veuillez contacter
                    votre référent clé {{ supportEmail$ | async }}.
                </p>
            </ng-container>
        </div>
        <div *ngIf="status === applicationStatusEnum.OK && !dataSuccess?.livraisons?.length" class="text-center pt-5">
            Aucun évènement trouvé
        </div>
    </div>
</div>
