import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { BetonLeonDto, VolumeEtPoidCarboneBetonParSemaine } from '../../../data-access/http/dto/beton-leon-dto'
import { Chart, TooltipItem } from 'chart.js/auto'
import * as dayjs from 'dayjs'
import { ChartData, ChartOptions, PointStyle } from 'chart.js/dist/types'
import { getGenericChartOptions } from '../../../../shared/utils/utils'
import { DecimalPipe } from '@angular/common'
import { newDateFromString } from '../../../../shared/utils/date.utils'

@Component({
    selector: 'app-beton-leon-portlet-body',
    templateUrl: './beton-leon-portlet-body.component.html',
    styleUrls: ['./beton-leon-portlet-body.component.scss'],
    standalone: true,
    imports: [DecimalPipe],
})
export class BetonLeonPortletBodyComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('chart')
    chartCanvas: ElementRef

    @Input()
    data: BetonLeonDto

    public chart: Chart
    private nbDataPoints: number = 8

    constructor(@Inject('DATA') private dataInjected: string) {}

    ngOnInit(): void {
        this.data = this.data || JSON.parse(this.dataInjected)
        this.data.dateDernierBL = newDateFromString(this.data.dateDernierBL)
    }

    ngAfterViewInit(): void {
        this.createChart()
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy()
        }
    }

    private createChart(): void {
        const data = this.buildChartData()
        this.chart = new Chart(this.chartCanvas.nativeElement.getContext('2d'), {
            type: 'line',
            data: data,
            options: this.buildChartOptions(data),
        })
    }

    private buildChartData(): ChartData {
        const weekLabels: string[] = []
        for (let i = 0; i < this.nbDataPoints; i++) {
            weekLabels.push(
                'S' +
                    dayjs()
                        .subtract(this.nbDataPoints - (i + 1), 'week')
                        .week(),
            )
        }

        const sortedData: VolumeEtPoidCarboneBetonParSemaine[] = []
        this.data.volumeEtPoidCarboneBetonParSemaine
            .filter((value) => value.numeroSemaine <= 0 && value.numeroSemaine > -this.nbDataPoints)
            .forEach((value) =>
                sortedData.push({
                    poidCarboneSemaine: value.poidCarboneSemaine,
                    volumeSemaine: value.volumeSemaine,
                    numeroSemaine: value.numeroSemaine,
                }),
            )

        for (let i = 0; i < this.nbDataPoints; i++) {
            if (!sortedData.find((value) => value.numeroSemaine === -i)) {
                sortedData.push({
                    poidCarboneSemaine: -1,
                    volumeSemaine: -1,
                    numeroSemaine: -i,
                })
            }
        }

        sortedData.sort((a, b) => a.numeroSemaine - b.numeroSemaine)
        const dataVolumeSemaine: number[] = sortedData.map((item) => item.volumeSemaine)
        const dataPoidCarboneSemaine: number[] = sortedData.map((item) => item.poidCarboneSemaine)

        return {
            labels: weekLabels,
            datasets: [
                {
                    label: 'Volume',
                    data: dataVolumeSemaine,
                    backgroundColor: 'black',
                    borderColor: 'black',
                    borderWidth: 1.8,
                    fill: {
                        target: 'origin',
                        above: 'rgba(0,0,0, 0.08)',
                    },
                },
                {
                    label: 'Poids Carbone',
                    data: dataPoidCarboneSemaine,
                    hidden: true,
                },
            ],
        }
    }

    private buildChartOptions(data: ChartData): ChartOptions {
        const tooltipLabel = (tooltipItem: TooltipItem<'line'>): string[] => {
            return data.datasets.map(
                (ds) => ds.label + ': ' + ds.data[tooltipItem.dataIndex] + (ds.label === 'Volume' ? ' m3' : ' TEqCO2'),
            )
        }

        const tooltipLabelPointStyle = (): { pointStyle: PointStyle; rotation: number } => {
            return {
                pointStyle: false,
                rotation: 0,
            }
        }

        const chartOptions = getGenericChartOptions()
        chartOptions.plugins.tooltip = {
            usePointStyle: true,
            boxWidth: 0,
            boxHeight: 0,
            callbacks: {
                label: tooltipLabel,
                labelPointStyle: tooltipLabelPointStyle,
            },
        }

        return chartOptions
    }
}
