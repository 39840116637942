import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { User } from '../../../shared/models/user'
import { filter } from 'rxjs'
import { NavigationEnd, Router, RouterLink } from '@angular/router'
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { AsyncPipe, NgFor, NgIf } from '@angular/common'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component'
import { UserWidgetComponent } from './user-widget/user-widget.component'
import { LogoDisplayComponent } from './logo-display/logo-display.component'
import { ContactWidgetComponent } from './contact-widget/contact-widget.component'
import { ChantierDTO } from '../../../shared/data-access/http/dto/chantier-dto'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        NgbDropdown,
        NgbDropdownToggle,
        NgbDropdownMenu,
        NgFor,
        NgbDropdownItem,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        AsyncPipe,
        BreadcrumbComponent,
        UserWidgetComponent,
        LogoDisplayComponent,
        ContactWidgetComponent,
    ],
})
export class HeaderComponent {
    @Input({ required: true })
    user: User
    @Input({ required: true })
    selectedChantier: ChantierDTO
    @Input({ required: true })
    selectableChantiers: ChantierDTO[]
    @Input({ required: true })
    isIris: boolean
    @Input({ required: true })
    supportEmail: string
    @Output()
    openSelectChantierModal = new EventEmitter<void>()
    @Output()
    logout = new EventEmitter<void>()
    @Output()
    selectChantier = new EventEmitter<string>()
    @Output()
    contactSupport = new EventEmitter<void>()

    protected isAdminPage = false
    protected isDroitsRHPage = false
    protected isAccueilChantierPage = false
    protected isAdminImportBetonPage = false
    protected isAccueilSecuritePage = false
    protected isUnauthorizedPage: boolean
    protected isAutorisationConduitePage = false

    private readonly router = inject(Router)

    constructor() {
        this.router.events
            .pipe(
                takeUntilDestroyed(),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe(() => {
                const path = this.router.url.split('?')[0]
                this.isAdminPage = path === '/admin'
                this.isDroitsRHPage = path === '/droitsrh'
                this.isAccueilChantierPage = path === '/accueil-chantier'
                this.isAccueilSecuritePage = path === '/accueil-chantier/securite'
                this.isAdminImportBetonPage = path === '/admin-import-beton'
                this.isUnauthorizedPage = path === '/user-not-authenticated'
                this.isAutorisationConduitePage = path === '/accueil-chantier/autorisation-conduite'
            })
    }

    get shouldDisplayBreadcrumb(): boolean {
        return (
            this.isAdminPage ||
            this.isDroitsRHPage ||
            this.isAccueilChantierPage ||
            this.isAccueilSecuritePage ||
            this.isAdminImportBetonPage ||
            this.isAutorisationConduitePage
        )
    }

    onSelectChantier(chantierCode: string): void {
        this.selectChantier.emit(chantierCode)
    }

    openSelectOtherChantierModal(): void {
        this.openSelectChantierModal.emit()
    }

    logOut(): void {
        this.logout.emit()
    }

    onContactSupport(): void {
        this.contactSupport.emit()
    }
}
