<div class="container ps-0">
    <div class="d-flex justify-content-between align-items-center py-4">
        <app-logo-display [isIris]="isIris"></app-logo-display>
        <div *ngIf="!shouldDisplayBreadcrumb" class="px-0 my-2">
            <div class="d-flex align-items-center justify-content-center ps-5">
                <div *ngIf="selectedChantier">
                    <div #dropdownChantier="ngbDropdown" class="d-inline-block" ngbDropdown>
                        <button
                            class="btn btn-outline-primary chantier-selector text-start"
                            id="chantierDropdown"
                            ngbDropdownToggle
                            type="button"
                        >
                            <div class="d-inline-block text-truncate align-text-bottom" style="max-width: 18rem">
                                {{ selectedChantier?.code + " - " + selectedChantier?.nom }}
                            </div>
                        </button>
                        <div
                            aria-labelledby="chantierDropdown"
                            class="overflow-auto"
                            ngbDropdownMenu
                            style="max-height: 20rem"
                        >
                            <button
                                (click)="onSelectChantier(chantier.code)"
                                *ngFor="let chantier of selectableChantiers"
                                class="chantier-button"
                                ngbDropdownItem
                            >
                                <div class="d-inline-block text-truncate">{{ chantier.code }} - {{ chantier.nom }}</div>
                            </button>
                            <button
                                (click)="dropdownChantier.close(); openSelectOtherChantierModal()"
                                class="chantier-button other-chantier-button"
                            >
                                <div class="d-inline-block">Accéder à un autre chantier</div>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedChantier === null && user && !isUnauthorizedPage">
                    <div class="d-inline-block">
                        <button
                            (click)="openSelectOtherChantierModal()"
                            class="btn btn-primary chantier-access-button"
                            type="button"
                        >
                            Accéder à un chantier
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-grow-1 w-100"></div>
        <div class="d-flex right-section align-items-center">
            <app-contact-widget role="button" [supportEmail]="supportEmail" (click)="onContactSupport()" />
            <app-user-widget [user]="user" (logout)="logOut()" class="pe-0" />
        </div>
    </div>
</div>
<nav
    *ngIf="shouldDisplayBreadcrumb"
    aria-label="breadcrumb"
    class="navbar navbar-chantier navbar-expand-lg sticky-top bg-white shadow-sm"
>
    <app-breadcrumb *ngIf="isAdminPage" [paths]="[{ label: 'Administration' }]" class="container ps-0 mt-3" />
    <app-breadcrumb
        *ngIf="isDroitsRHPage"
        [paths]="[{ label: 'Données RH Collaborateurs' }]"
        class="container ps-0 mt-3"
    />
    <app-breadcrumb
        *ngIf="isAccueilChantierPage"
        [paths]="[{ label: 'Accueil chantier' }]"
        class="container ps-0 mt-3"
    />
    <app-breadcrumb
        *ngIf="isAccueilSecuritePage"
        [paths]="[{ label: 'Accueil chantier', lien: '/accueil-chantier' }, { label: 'Accueil sécurité sur chantier' }]"
        class="container ps-0 mt-3"
    />
    <app-breadcrumb
        *ngIf="isAdminImportBetonPage"
        [paths]="[{ label: 'Administration', lien: '/admin' }, { label: 'Administration Import Béton' }]"
        class="container ps-0 mt-3"
    />

    <app-breadcrumb
        *ngIf="isAutorisationConduitePage"
        [paths]="[{ label: 'Accueil chantier', lien: '/accueil-chantier' }, { label: 'Autorisation de conduite' }]"
        class="container ps-0 mt-3"
    />
</nav>
