<div class="container py-0 px-0 h-100">
    <div class="row pb-1">
        <div class="col-7 text-truncate" title="Date du dernier BL">Date du dernier BL</div>
        <div class="col-5">
            <div class="row ms-1">
                <div class="fw-semibold text-nowrap ps-0 fs-6">
                    {{ data.dateDernierBL.toLocaleDateString() }}
                </div>
            </div>
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-7 text-truncate" title="Quantité totale">Quantité totale</div>
        <div class="col-5">
            <div class="row ms-1">
                <div class="fw-semibold text-nowrap ps-0 fs-6">{{ data.quantiteBetonTotale | number: "1.0-2" }} m3</div>
            </div>
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-7 text-truncate" title="Poids carbone">Poids carbone</div>
        <div class="col-5">
            <div class="row ms-1">
                <div class="fw-semibold text-nowrap ps-0 fs-6">
                    {{ data.poidCarboneBetonTotal / 1000 | number: "1.0-2" }} TEqCO2
                </div>
            </div>
        </div>
    </div>
    <div class="row h-100 my-3 chart-container">
        <canvas #chart id="chart">{{ chart }}</canvas>
    </div>
</div>
