<div class="container py-0 px-0 h-100">
    <ng-container *ngIf="thereIsData">
        <div class="row">
            <div #dernierPointage class="col-7 text-truncate" [title]="dernierPointage.innerText">
                Date de dernier pointage
            </div>
            <div class="col-5">
                <div class="row ms-1">
                    <div
                        [ngClass]="
                            isDateBefore7DaysAgo(data.datePointageEncours) ||
                            isMonthDifferentFromToday(data.datePointageEncours)
                                ? 'red'
                                : isDateAfterYesterday(data.datePointageEncours)
                                  ? 'green'
                                  : 'orange'
                        "
                        class="fw-semibold text-nowrap ps-0 fs-6"
                    >
                        {{ data.datePointageEncours?.toLocaleDateString() }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-7 my-2 border-right bg-white text-truncate" title="Effectifs à cette date">
                Effectifs à cette date
            </div>
            <div class="col-5">
                <div class="row ms-1">
                    <div class="fw-semibold text-nowrap ps-0 fs-6">
                        {{ data.nbCompagnonsLG }} <span class="red text-truncate" title="Internes">Internes</span>
                    </div>
                </div>
                <div class="row ms-1">
                    <div class="fw-semibold text-nowrap ps-0 fs-6">
                        {{ data.nbCompagnonsETT }}
                        <span class="blue text-truncate" title="Intérimaires">Intérimaires</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-100 my-3 chart-container">
            <canvas #chart id="chart">{{ chart }}</canvas>
        </div>
    </ng-container>
    <div *ngIf="!thereIsData" class="text-center h-100 pt-4">Aucun pointage</div>
</div>
