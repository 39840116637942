import { Injectable } from '@angular/core'
import { Store } from '../../../shared/models/store'
import { ApplicationDto } from '../http/dto/application.dto'
import { ApplicationInfoDto } from '../http/dto/application-info.dto'
import { Application } from '../../../shared/models/application'
import { TabSectionEnum } from '../constant/tab-data'

interface ApplicationStateModel {
    applications: ApplicationDto[]
    applicationInfos: ApplicationInfoDto[]
    applicationsOutils: Application[]
    selectedTab: TabSectionEnum
}

@Injectable({ providedIn: 'root' })
export class ApplicationState {
    store = new Store<ApplicationStateModel>({
        applications: [],
        applicationInfos: [],
        applicationsOutils: [],
        selectedTab: TabSectionEnum.SuiviChantier,
    })

    // Selectors
    static getApplications = (state: ApplicationStateModel): ApplicationDto[] => state.applications
    static getApplicationInfos = (state: ApplicationStateModel): ApplicationInfoDto[] => state.applicationInfos
    static getApplicationsOutils = (state: ApplicationStateModel): Application[] => state.applicationsOutils
    static getSelectedTab = (state: ApplicationStateModel): TabSectionEnum => state.selectedTab

    // Store update methods
    pushApplicationInfo(appInfoDto: ApplicationInfoDto): void {
        this.store.update((state) => ({
            ...state,
            applicationInfos: [...state.applicationInfos, appInfoDto],
        }))
    }

    resetApplicationInfos(): void {
        this.store.update((state) => ({
            ...state,
            applicationInfos: [],
        }))
    }
}
