import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { from, lastValueFrom, Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { OAuthService } from 'angular-oauth2-oidc'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private oAuthService: OAuthService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return from(this.handle(req, next))
    }

    private async handle(req: HttpRequest<unknown>, next: HttpHandler): Promise<HttpEvent<unknown>> {
        const lambdaUploadPath = '/media/signed-url/generate/upload'
        if (RegExp(environment.baseUrl).exec(req.url) || req.url.includes(lambdaUploadPath)) {
            req = await this.addAuthorizationHeader(req)
        }
        return lastValueFrom(next.handle(req))
    }

    private async addAuthorizationHeader(req: HttpRequest<unknown>): Promise<HttpRequest<unknown>> {
        await this.refreshToken()

        if (this.oAuthService.hasValidIdToken()) {
            req = req.clone({
                setHeaders: { Authorization: `Bearer ${this.oAuthService.getIdToken()}` },
            })
        }
        return req
    }

    private refreshToken(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (+new Date() >= this.oAuthService.getAccessTokenExpiration() && this.oAuthService.getRefreshToken()) {
                void this.oAuthService.refreshToken().then(() => resolve())
            } else {
                resolve()
            }
        })
    }
}
