import { Component } from '@angular/core'
import { NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-planning-livraison-portlet-body',
    templateUrl: './affectation-taches-portlet-body.component.html',
    styleUrls: ['./affectation-taches-portlet-body.component.scss'],
    standalone: true,
    imports: [NgOptimizedImage],
})
export class AffectationTachesPortletBodyComponent {
    protected readonly imgUrl = 'assets/images/home/task-assignment-body.svg'
}
