import { inject, Injectable } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError, Observable, throwError } from 'rxjs'
import { ToastrService } from 'ngx-toastr'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private toastr = inject(ToastrService)

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error) => {
                this.showError(error)
                return throwError(() => error)
            }),
        )
    }

    private showError(httpErrorResponse: HttpErrorResponse): void {
        this.showErrorToast(httpErrorResponse)
    }

    private showErrorToast(httpErrorResponse: HttpErrorResponse): void {
        if (typeof httpErrorResponse.error?.error_description === 'string') {
            this.toastr.error(httpErrorResponse.error?.error_description, `Erreur ${httpErrorResponse.status}`)
        } else if (httpErrorResponse.error) {
            this.toastr.error(httpErrorResponse.error.message, `Erreur ${httpErrorResponse.status}`)
        }
    }
}
