export function newDateFromString(stringDate: string | Date): Date {
    if (!stringDate) {
        return undefined
    }

    const date: Date = new Date(stringDate)
    return isNaN(date.getMilliseconds()) ? undefined : date
}

export function parseCustomDate(dateString: string): Date | null {
    if (!dateString) {
        return null
    }
    const year = parseInt(dateString.slice(0, 4), 10)
    const month = parseInt(dateString.slice(4, 6), 10) - 1 // Month is 0-based in Date object
    const day = parseInt(dateString.slice(6, 8), 10)
    const hour = parseInt(dateString.slice(8, 10), 10)
    const minute = parseInt(dateString.slice(10, 12), 10)
    const second = parseInt(dateString.slice(12, 14), 10)

    // Check if the parsed components are valid
    if (isNaN(year) || isNaN(month) || isNaN(day) || isNaN(hour) || isNaN(minute) || isNaN(second)) {
        return null
    }

    return new Date(year, month, day, hour, minute, second)
}

export function removeTimeFromDateTime(dateTime: Date): Date {
    return new Date(dateTime.toDateString())
}

export function isTodayOrLater(date: Date): boolean {
    return date.getTime() >= removeTimeFromDateTime(new Date()).getTime()
}

export function isInThePast(date: Date): boolean {
    return date.getTime() < removeTimeFromDateTime(new Date()).getTime()
}

export function toCustomDate(standardDateString: string): string {
    if (dateIsEnglish(standardDateString)) {
        return standardDateString.slice(0, 10).replaceAll('-', '') + '000000'
    }
    if (dateIsFrench(standardDateString)) {
        const day = standardDateString.slice(0, 2)
        const month = standardDateString.slice(3, 5)
        const year = standardDateString.slice(6, 10)
        return year + month + day + '000000'
    }
    return null
}

export function dateIsFrench(date: string): boolean {
    // 2024-03-08
    return /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}/.test(date)
}

export function dateIsEnglish(date: string): boolean {
    // 08/03/2024
    return /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])/.test(date)
}


export function toEnglishDate(date: Date): string {
    return date.getFullYear()+'-'+(+date.getMonth()+1).toString().padStart(2,'0')+'-'+date.getDate().toString().padStart(2,'0')
}
