import { Pipe, PipeTransform } from '@angular/core'
import { formatDate } from '@angular/common'

@Pipe({ standalone: true, name: 'displayDate' })
export class DisplayDatePipe implements PipeTransform {
    transform(value: Date): string {
        if (DisplayDatePipe.dateIsToday(value)) {
            return "Aujourd'hui"
        }
        if (DisplayDatePipe.dateIsTomorrow(value)) {
            return 'Demain'
        }
        return formatDate(value, 'shortDate', 'fr')
    }

    private static dateIsToday(date: Date): boolean {
        const today = new Date()
        return today.toDateString() === date.toDateString()
    }

    private static dateIsTomorrow(date: Date): boolean {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        return tomorrow.toDateString() === date.toDateString()
    }
}
