import { Component } from '@angular/core'
import { NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-stac-portlet-body',
    templateUrl: './stac-portlet-body.component.html',
    styleUrls: ['./stac-portlet-body.component.scss'],
    standalone: true,
    imports: [NgOptimizedImage],
})
export class StacPortletBodyComponent {
    protected readonly imgUrl = 'assets/images/home/stac-body.svg'
}
