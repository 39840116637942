import { Component, Inject, OnInit } from '@angular/core'
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap'
import { DatePipe, DecimalPipe, NgFor, NgIf, SlicePipe } from '@angular/common'
import { DisplayDatePipe } from '../../../../shared/pipes/display-date.pipe'
import { ActionsBetonLeonInfos } from '../../../../shared/models/actions-beton-leon'
import { ActionsBetonLeonDto } from '../../../data-access/http/dto/actions-beton-leon.dto'

@Component({
    selector: 'app-actions-beton-leon',
    templateUrl: './actions-beton-leon-portlet-body.component.html',
    styleUrls: ['./actions-beton-leon-portlet-body.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgbPagination, SlicePipe, DatePipe, DisplayDatePipe, DecimalPipe],
})
export class ActionsBetonLeonPortletBodyComponent implements OnInit {
    data: ActionsBetonLeonInfos
    constructor(
        @Inject('DATA') private dataInjected: string,
        @Inject('HIGHLIGHTED') public highlighted: boolean,
    ) {}

    ngOnInit(): void {
        this.data = JSON.parse(this.dataInjected) as ActionsBetonLeonDto
    }
}
