import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { OAuthService } from 'angular-oauth2-oidc'
import { getUrlWithoutQueryParams } from '../../shared/utils/utils'
import { UserDomainService } from '../../shared/domain/user.domain'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    connectedUserHasBeenLoadedOnce = false
    userDomainService = inject(UserDomainService)

    constructor(
        private oAuthService: OAuthService,
        private router: Router,
    ) {}

    // noinspection JSUnusedGlobalSymbols
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const connectedUser = this.userDomainService.connectedUser()
        if (this.oAuthService.hasValidIdToken() && connectedUser) {
            this.redirectToSurChantierIfNeeded(getUrlWithoutQueryParams(state.url))
            return true
        } else if (this.oAuthService.hasValidIdToken() && !connectedUser && this.connectedUserHasBeenLoadedOnce) {
            if (state.url !== '/user-not-authenticated') {
                void this.router.navigate(['/user-not-authenticated'], { skipLocationChange: false })
                return false
            } else {
                return true
            }
        }
        return new Observable<boolean>((observer) => {
            this.oAuthService
                .tryLogin()
                .then(() => {
                    if (!this.oAuthService.hasValidIdToken()) {
                        this.oAuthService.initCodeFlow()
                    } else {
                        this.userDomainService.loadConnectedUser().subscribe({
                            next: () => {
                                this.connectedUserHasBeenLoadedOnce = true
                                this.redirectToSurChantierIfNeeded(getUrlWithoutQueryParams(state.url))

                                observer.next(true)
                                observer.complete()
                            },
                            error: () => {
                                this.connectedUserHasBeenLoadedOnce = true
                                void this.router.navigate(['/user-not-authenticated'], { skipLocationChange: false })

                                observer.next(false)
                                observer.complete()
                            },
                        })
                    }
                })
                .catch(undefined)
        })
    }

    // noinspection JSUnusedGlobalSymbols
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const connectedUser = this.userDomainService.connectedUser()
        if (state.url === '/admin' && !connectedUser.superAdmin) {
            void this.router.navigate(['/'])
        }
        if (state.url === '/droitsrh' && !connectedUser.droitsRH) {
            void this.router.navigate(['/'])
        }
        if (state.url === '/user-not-authenticated' && connectedUser) {
            void this.router.navigate(['/'])
        }
        this.redirectToSurChantierIfNeeded(state.url)

        const chantierCode = childRoute.paramMap.get('chantierCode')
        const userKnowsChantier = !!connectedUser?.chantiers?.find((c) => c.code === chantierCode)
        if (chantierCode && !userKnowsChantier) {
            this.redirectToSurChantierIfNeeded('/')
        }

        return true
    }

    redirectToSurChantierIfNeeded(url: string): void {
        if (url === '/') {
            const chantierCode = this.userDomainService.connectedUser()?.getChantierCode()
            if (chantierCode) {
                void this.router.navigate(['/chantiers', chantierCode])
            } else {
                void this.router.navigate(['/no-chantier'], { skipLocationChange: false })
            }
        }
    }
}
