import { Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'
import { NgIf } from '@angular/common'

@Component({
    selector: 'app-logo-display',
    standalone: true,
    imports: [RouterLink, NgIf],
    templateUrl: './logo-display.component.html',
    styleUrl: './logo-display.component.scss',
})
export class LogoDisplayComponent {
    @Input() isIris: boolean
}
