import { Component, inject } from '@angular/core'
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common'
import { ChantierDomainService } from '../../../../shared/domain/chantier.domain'
import { Observable } from 'rxjs'
import { NgLetDirective } from '../../../../shared/directives/ng-let.directive'

@Component({
    selector: 'app-gestion-droits-portlet-body',
    templateUrl: './gestion-droits-portlet-body.component.html',
    styleUrls: ['./gestion-droits-portlet-body.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, AsyncPipe, NgLetDirective],
})
export class GestionDroitsPortletBodyComponent {
    emailsAdminsChantier$: Observable<string[]>
    userIsAdminChantier$: Observable<boolean>
    protected supportEmail$: Observable<string>
    private chantierDomainService: ChantierDomainService

    constructor() {
        this.chantierDomainService = inject(ChantierDomainService)
        this.emailsAdminsChantier$ = this.chantierDomainService.selectedChantierAdmins$
        this.userIsAdminChantier$ = this.chantierDomainService.userIsAdminChantier$
        this.supportEmail$ = this.chantierDomainService.supportEmail$
    }
}
