<div class="container py-0 px-0 h-100">
    <div class="h-100">
        <div class="d-flex justify-content-between mb-3">
            <div class="fw-bold">Date du dernier import :</div>
            <div>{{ data.dateDernierImportBL }}</div>
        </div>
        <div class="d-flex justify-content-between mb-3">
            <div class="fw-bold">Actions à mener :</div>
            <div></div>
        </div>
        <div class="d-flex justify-content-between">
            <div>Nombre de BL non-ventilés :</div>
            <div>{{ data.nbBLNonVentiles }}</div>
        </div>
        <div class="d-flex justify-content-between">
            <div>Nombre de formules non reconnues :</div>
            <div>{{ data.nbFormulesNonReconnues }}</div>
        </div>
        <div class="d-flex justify-content-between">
            <div>Nombre de formules sans tarif :</div>
            <div>{{ data.nbFormulesSansTarif }}</div>
        </div>
        <div class="d-flex justify-content-between last-number">
            <div>Nombre de formules sans poids carbone :</div>
            <div>{{ data.nbFormulesSansPoidsCarbone }}</div>
        </div>
        <div *ngIf="!highlighted" class="text-end">
            <i class="bi bi-check-circle check-icon"></i>
        </div>
    </div>
</div>
