<div class="container" *ngLet="contactCsm$ | async as contactCsm">
    <ng-container *ngLet="shouldDisplayContactCSM$ | async as shouldDisplayContactCsm">
        <ng-container>
            <div *ngIf="shouldDisplayContactCsm || supportEmail" class="row py-1">
                <div class="d-flex justify-content-center pt-2 text-white">Besoin d'assistance ?</div>
            </div>
            <div *ngIf="supportEmail" class="row py-1">
                <div class="d-flex justify-content-center pt-2">
                    <a class="fit-content text-white" href="mailto:{{ supportEmail }}" target="_blank">
                        <i class="bi bi-envelope-fill"></i>&nbsp;&nbsp;&nbsp;{{ supportEmail }}
                    </a>
                </div>
            </div>
            <div *ngIf="shouldDisplayContactCsm" class="row py-1">
                <div class="d-flex justify-content-center pt-2 text-white">
                    <i class="bi bi-telephone-fill"></i>&nbsp;&nbsp;&nbsp;{{ contactCsm.nom }} :
                    {{ contactCsm.telephone }}
                </div>
            </div>
        </ng-container>
    </ng-container>

    <div class="row">
        <img alt="" class="footer-logo" height="100rem" src="../../../../assets/images/logos/logo-lg-gray.png" />
    </div>
    <div class="row">
        <div class="d-flex justify-content-center py-4 text-color-neutral">
            Portail Chantier / Version Front {{ version }} / Version Back {{ backendVersion }}
        </div>
    </div>
</div>
