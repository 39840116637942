import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { CarteBtpFormatPipe } from '../../../../shared/pipes/carte-btp-format.pipe'
import { keepOnlyDigits, reloadRoute, validateOnlyDigitsAndSize } from '../../../../shared/utils/utils'
import { SwitchInputComponent } from '../../../../shared/ui/switch-input/switch-input.component'
import { carteBtpFormatValidator } from '../../../../shared/validators/carte-btp-format.validator'

@Component({
    selector: 'app-donnees-rh-portlet-body',
    templateUrl: './donnees-rh-portlet-body.component.html',
    styleUrls: ['./donnees-rh-portlet-body.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, SwitchInputComponent],
})
export class DonneesRhPortletBodyComponent implements OnInit {
    searchByCarteBTPForm: FormGroup
    private carteBtpFormatPipe = new CarteBtpFormatPipe()

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.searchByCarteBTPForm = this.formBuilder.group({
            carteBTPNumber: ['', [Validators.required, carteBtpFormatValidator()]],
            collabSwitch: [false],
        })
    }

    onSearchByCarteBTPFormSubmit(): void {
        const carteBtp = keepOnlyDigits(this.searchByCarteBTPForm.get('carteBTPNumber').value)
        const collabSwitch = !!this.searchByCarteBTPForm.get('collabSwitch').value
        reloadRoute(this.router, ['/droitsrh'], { queryParams: { carteBtp: carteBtp, interimSearch: collabSwitch } })
    }

    onCarteBTPInputChange(): void {
        this.searchByCarteBTPForm.patchValue({
            carteBTPNumber: this.carteBtpFormatPipe.transform(this.searchByCarteBTPForm.get('carteBTPNumber').value),
        })
    }

    validateKey($event: KeyboardEvent): void {
        validateOnlyDigitsAndSize($event, this.searchByCarteBTPForm.get('carteBTPNumber').value, 10)
    }
}
