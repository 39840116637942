import { Component } from '@angular/core'

@Component({
    selector: 'app-lg-no-chantier',
    templateUrl: './no-chantier.component.html',
    standalone: true,
})
export class NoChantierComponent {
    constructor() {
        // Nothing to see here
    }
}
