import { inject, Injectable } from '@angular/core'
import { ChantierState } from '../data-access/state/chantier.state'
import { ChantierDTO } from '../data-access/http/dto/chantier-dto'
import { UserState } from '../data-access/state/user.state'
import { combineLatestWith, map, Observable, switchMap, tap } from 'rxjs'
import { ChantierHttpService } from '../data-access/http/chantier-http.service'
import { ApplicationDomainService } from '../../home/domain/application.domain'
import { Location } from '@angular/common'
import { ContactCsm } from '../models/contact-csm'
import { ParamDomainService } from './param.domain'
import { ParamCodeEnum } from '../models/enums/param-code.enum'
//@ts-expect-error This library doesn't include ts types, so we bypass TS in order to use it
import HtmlSanitizer from 'jitbit-html-sanitizer'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Injectable({ providedIn: 'root' })
export class ChantierDomainService {
    private readonly chantierState = inject(ChantierState)
    private readonly location = inject(Location)
    private readonly chantierHttpService = inject(ChantierHttpService)
    private readonly applicationDomainService = inject(ApplicationDomainService)
    private readonly paramDomainService = inject(ParamDomainService)
    private readonly sanitizer = inject(DomSanitizer)

    private userState = inject(UserState)

    // Selectors
    selectedChantier$ = this.chantierState.store.select(ChantierState.getSelectedChantier)
    selectedChantierPhotoUrl$ = this.chantierState.store.select(ChantierState.getSelectedChantierPhotoUrl)
    selectableChantiers$ = this.userState.store.select(UserState.getUserChantiers).pipe(
        combineLatestWith(this.chantierState.store.select(ChantierState.getSelectedChantier)),
        map(([chantiers, selectedChantier]) =>
            chantiers.filter((chantier) => chantier.code !== selectedChantier?.code),
        ),
    )
    selectedChantierAdmins$ = this.chantierState.store.select(ChantierState.getSelectedChantierAdmins)
    selectedChantierCSM$: Observable<ContactCsm> = this.chantierState.store.select(ChantierState.getSelectedChantierCSM)
    userIsAdminChantier$: Observable<boolean> = this.chantierState.store
        .select(ChantierState.getSelectedChantier)
        .pipe(map((selectedChantier) => selectedChantier.admin))
    selectedChantierIsIris$: Observable<boolean> = this.chantierState.store
        .select(ChantierState.getSelectedChantier)
        .pipe(map((selectedChantier) => selectedChantier?.iris))
    actualiteChantiers$: Observable<SafeHtml> = this.selectedChantierIsIris$.pipe(
        map((isIris) => {
            if (isIris) {
                return this.paramDomainService.getParamByCode(ParamCodeEnum.global_message_iris)?.valeurParam
            }
            return this.paramDomainService.getParamByCode(ParamCodeEnum.global_message)?.valeurParam
        }),
        map((actualiteChantiers) =>
            this.sanitizer.bypassSecurityTrustHtml(new HtmlSanitizer({}).sanitizeHtml(actualiteChantiers || '')),
        ),
    )
    supportEmail$: Observable<string> = this.paramDomainService.params$.pipe(
        combineLatestWith(this.chantierState.store.select(ChantierState.getSelectedChantier)),
        map(([params, selectedChantier]) => {
            const paramCode: ParamCodeEnum = selectedChantier?.iris
                ? ParamCodeEnum.footer_contact_mail_iris
                : ParamCodeEnum.footer_contact_mail
            return params?.find((param) => param.codeParam === paramCode)?.valeurParam
        }),
    )

    selectedChantierCode(): string {
        return this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
    }

    chantierIsSelectable(chantierCode: string): boolean {
        return this.userState.store
            .selectSnapshot(UserState.getUserChantiers)
            .some((chantier) => chantier.code === chantierCode)
    }

    chantierIsSelected(chantierCode: string): boolean {
        return this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode) === chantierCode
    }

    supportEmail(): string {
        const selectedChantierIsIris = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantier)?.iris
        return selectedChantierIsIris
            ? this.paramDomainService.getParamByCode(ParamCodeEnum.footer_contact_mail_iris)?.valeurParam
            : this.paramDomainService.getParamByCode(ParamCodeEnum.footer_contact_mail)?.valeurParam
    }

    // Actions
    setSelectedChantier(chantierCode: string): void {
        const selectedChantier = this.userState.store
            .selectSnapshot(UserState.getUserChantiers)
            .find((chantier) => chantier.code === chantierCode)
        if (selectedChantier) {
            this.chantierState.store.update((state) => ({ ...state, selectedChantier }))
            this.location.replaceState('/chantiers/' + selectedChantier.code)
            this.applicationDomainService.loadApplicationsOutils()
            this.applicationDomainService
                .loadApplications()
                .pipe(switchMap(() => this.applicationDomainService.loadApplicationsData()))
                .subscribe()
            this.chantierHttpService
                .getChantierAdminEmails(selectedChantier.code, 4)
                .pipe(
                    tap((selectedChantierAdmins) => {
                        this.chantierState.store.update((state) => ({ ...state, selectedChantierAdmins }))
                    }),
                )
                .subscribe()
            this.loadPhotoSelectedChantier().subscribe()
        }
    }

    loadPhotoSelectedChantier(): Observable<ChantierDTO> {
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        return this.chantierHttpService.getChantierWithPhoto(selectedChantierCode).pipe(
            tap((chantier) =>
                this.chantierState.store.update((state) => ({
                    ...state,
                    selectedChantier: {
                        ...state.selectedChantier,
                        urlPhotoChantier: chantier.urlPhotoChantier,
                        auteurPhotoChantier: chantier.auteurPhotoChantier,
                    },
                })),
            ),
        )
    }

    addPhotoToSelectedChantier(fileName: string, lambdaId: string): Observable<ChantierDTO> {
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        return this.chantierHttpService
            .setChantierPhoto({ codeChantier: selectedChantierCode, fileName, lambdaId })
            .pipe(
                tap((chantierDto) => {
                    const { urlPhotoChantier, auteurPhotoChantier } = chantierDto
                    if (urlPhotoChantier) {
                        this.chantierState.store.update((state) => ({
                            ...state,
                            selectedChantier: { ...state.selectedChantier, urlPhotoChantier, auteurPhotoChantier },
                        }))
                    }
                }),
            )
    }

    deletePhotoSelectedChantier(): Observable<string> {
        const selectedChantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        return this.chantierHttpService.deleteChantierPhoto(selectedChantierCode).pipe(
            tap(() => {
                this.chantierState.store.update((selectedChantier) => ({
                    ...selectedChantier,
                    auteurPhotoChantier: null,
                    urlPhotoChantier: null,
                }))
            }),
        )
    }
}
