export enum ApplicationCodeEnum {
    POINTAGE_COMPAGNONS = 'POINTAGE_COMPAGNONS',
    BETON_LEON = 'BETON_LEON',
    LOCMAT = 'LOCMAT',
    DONNEES_INTERIMAIRES = 'DONNEES_INTERIMAIRES',
    KAIRNIAL = 'KAIRNIAL',
    GESTION_DROITS = 'GESTION_DROITS',
    DONNEES_RH = 'DONNEES_RH',
    PLANNING_LIVRAISON_CHANTIER = 'PLANNING_LIVRAISON_CHANTIER',
    AT360_ENTITES = 'AT360_ENTITES',
    AT360_CHANTIER = 'AT360_CHANTIER',
    ACCUEIL_CHANTIER = 'ACCUEIL_CHANTIER',
    POINTAGE_CHANTIER_360 = 'POINTAGE_CHANTIER_360',
    MES_IDEES = 'MES_IDEES',
    INFOS_SECURITE = 'INFOS_SECURITE',
    FORM_OUTILS_INFO = 'FORM_OUTILS_INFO',
    ACTIONS_BETON_LEON = 'ACTIONS_BETON_LEON',
    TASK_ASSIGNMENT = 'TASK_ASSIGNMENT',
    BOOSTER_BOX = 'BOOSTER_BOX',
    CATALOGUE_SERVICE_MATERIEL = 'CATALOGUE_SERVICE_MATERIEL',
    ACHATS_360 = 'ACHATS_360',
    WIFI = 'WIFI',
    STAC = 'STAC',
    AUDITS_LOGEMENTS = 'AUDITS_LOGEMENTS',
    RENDEZ_VOUS_LOCATAIRES = 'RENDEZ_VOUS_LOCATAIRES',
}
