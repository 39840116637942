import { Component } from '@angular/core'
import { NgForOf, NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-pointage360-portlet-body',
    standalone: true,
    imports: [NgForOf, NgOptimizedImage],
    templateUrl: './pointage360-portlet-body.component.html',
    styleUrl: './pointage360-portlet-body.component.scss',
})
export class Pointage360PortletBodyComponent {
    protected readonly imgUrl = 'assets/images/home/pointage360-body.jpg'
}
