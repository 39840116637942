<div class="container py-0 px-0 h-100">
    <div class="row pb-1">
        <div class="col-9 text-truncate" title="Nombre de matériels suivis">Nombre de matériels suivis</div>
        <div class="col-3">
            <div class="row ms-1 text-end">
                <div class="fw-semibold text-nowrap ps-0 fs-6">{{ data.nbMatSuivisSurChantier }}</div>
            </div>
        </div>
    </div>
    <div class="row pb-1">
        <div class="col-7 text-truncate" title="Date du dernier pointage">Date du dernier pointage</div>
        <div class="col-5">
            <div class="row ms-1">
                <div class="fw-semibold text-nowrap ps-0 fs-6 text-end">
                    {{ data?.dateDernierPointage ? data.dateDernierPointage.toLocaleDateString() : "-" }}
                </div>
            </div>
        </div>
    </div>
    <div class="pb-1 d-flex justify-content-center gap-2">
        <ng-container *ngFor="let photo of photos$ | async">
            <div *ngIf="photo" class="square">
                <img width="200" height="200" [ngSrc]="photo.lambdaUrl" alt="image1" />
            </div>
        </ng-container>
    </div>
</div>
