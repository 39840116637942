import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { EvenementDto } from './dto/evenement-dto'

@Injectable({
    providedIn: 'root',
})
export class EvenementHttpService {
    private static readonly URL = environment.baseUrl + '/evenements'
    private readonly http = inject(HttpClient)

    createEvenement(evenementDto: EvenementDto): Observable<EvenementDto> {
        return this.http.post<EvenementDto>(EvenementHttpService.URL, evenementDto)
    }
}
