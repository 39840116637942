import { ResolveFn } from '@angular/router'
import { Observable } from 'rxjs'
import { inject } from '@angular/core'
import { ParamDomainService } from '../domain/param.domain'
import { ParamDto } from '../data-access/http/dto/param-dto'

export const globalResolver: ResolveFn<ParamDto[]> = (): Observable<ParamDto[]> => {
    const paramDomainService = inject(ParamDomainService)
    return paramDomainService.loadParams()
}
