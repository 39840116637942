import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'

@Directive({
    selector: '[ngLet]',
    standalone: true,
})
export class NgLetDirective<T> {
    private context: NgLetContext<T | null> = { ngLet: null, $implicit: null }
    private hasView = false

    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<NgLetContext<T>>,
    ) {}

    @Input()
    set ngLet(value: T) {
        this.context.$implicit = this.context.ngLet = value
        if (!this.hasView) {
            this.hasView = true
            this.viewContainer.createEmbeddedView(this.templateRef, this.context)
        }
    }
}

interface NgLetContext<T> {
    /**
     * using `ngrxLet` to enable `as` syntax: `*ngLet="foo as bar"`
     */
    ngLet: T
    /**
     * using `$implicit` to enable `let` syntax: `*ngLet="foo; let bar"`
     */
    $implicit: T
}
