import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { FooterComponent } from '../../ui/footer/footer.component'
import { RouterOutlet } from '@angular/router'
import { HeaderComponent } from '../../ui/header/header.component'
import { UserDomainService } from '../../../shared/domain/user.domain'
import { ParamDomainService } from '../../../shared/domain/param.domain'
import { VersionService } from '../../data-access/http/version.service'
import { Observable } from 'rxjs'
import { AsyncPipe, DOCUMENT } from '@angular/common'
import { SelectOtherChantierModalComponent } from '../../ui/select-other-chantier-modal/select-other-chantier-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { User } from '../../../shared/models/user'
import { ChantierDomainService } from '../../../shared/domain/chantier.domain'
import { EvenementDomainService } from '../../../shared/domain/evenement.domain'
import { ChantierDTO } from '../../../shared/data-access/http/dto/chantier-dto'

@Component({
    selector: 'app-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent, AsyncPipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLayoutComponent implements OnInit {
    protected readonly selectedChantier$: Observable<ChantierDTO>
    protected readonly selectableChantiers$: Observable<ChantierDTO[]>
    protected readonly selectedChantierIsIris$: Observable<boolean>
    protected readonly backendVersion$: Observable<string>
    protected readonly supportEmail$: Observable<string>
    protected readonly user: User
    private readonly userDomainService: UserDomainService
    private readonly paramDomainService: ParamDomainService
    private readonly versionService: VersionService
    private readonly modalService: NgbModal
    private readonly chantierDomainService: ChantierDomainService
    private readonly evenementDomainService: EvenementDomainService
    private readonly window: Window

    constructor() {
        this.userDomainService = inject(UserDomainService)
        this.paramDomainService = inject(ParamDomainService)
        this.versionService = inject(VersionService)
        this.modalService = inject(NgbModal)
        this.chantierDomainService = inject(ChantierDomainService)
        this.evenementDomainService = inject(EvenementDomainService)
        this.window = inject(DOCUMENT).defaultView

        this.backendVersion$ = this.versionService.getVersionBackend()
        this.user = this.userDomainService.connectedUser()
        this.selectedChantier$ = this.chantierDomainService.selectedChantier$
        this.selectableChantiers$ = this.chantierDomainService.selectableChantiers$
        this.selectedChantierIsIris$ = this.chantierDomainService.selectedChantierIsIris$
        this.supportEmail$ = this.chantierDomainService.supportEmail$
    }

    ngOnInit(): void {
        if (this.user) {
            this.paramDomainService.loadParams().subscribe()
        }
    }

    selectChantier(chantierCode: string): void {
        this.chantierDomainService.setSelectedChantier(chantierCode)

        if (chantierCode) {
            this.userDomainService.setDernierChantierCode(chantierCode).subscribe()
            this.evenementDomainService.createSelectionChantierEvenement(chantierCode).subscribe()
        }
    }

    openSelectChantierModal(): void {
        this.modalService.open(SelectOtherChantierModalComponent)
    }

    logOut(): void {
        this.userDomainService.logOut()
    }

    onContactSupport(): void {
        const supportEmail = this.chantierDomainService.supportEmail()
        this.window.open(`mailto:${supportEmail}`)
    }
}
