import { Injectable } from '@angular/core'
import { Store } from '../../models/store'
import { ParamDto } from '../http/dto/param-dto'
import { ParamCodeEnum } from '../../models/enums/param-code.enum'

@Injectable({ providedIn: 'root' })
export class ParamState {
    store = new Store<ParamDto[]>([])

    static getParams = (state: ParamDto[]): ParamDto[] => state
    static getParamByCode =
        (codeParam: ParamCodeEnum) =>
        (state: ParamDto[]): ParamDto =>
            state.find((param) => param.codeParam === codeParam)
}
