import { inject } from '@angular/core'
import { UserDomainService } from '../../shared/domain/user.domain'
import { CanActivateFn, Router } from '@angular/router'

export function droitsrhGuard(): CanActivateFn {
    return () => {
        const hasDroitsRh = inject(UserDomainService).connectedUser().droitsRH
        if (!hasDroitsRh) {
            inject(Router).navigate(['/not-found'])
        }
        return hasDroitsRh
    }
}
