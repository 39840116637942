import { Component, HostBinding, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
    selector: 'app-switch-input',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './switch-input.component.html',
    styleUrls: ['./switch-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SwitchInputComponent,
            multi: true,
        },
    ],
})
export class SwitchInputComponent implements ControlValueAccessor {
    @Input()
    leftLabel: string
    @Input()
    rightLabel: string
    @Input()
    disabled = false
    @HostBinding('style.--leftColor')
    @Input()
    leftColor: string = '#e31b18'
    @HostBinding('style.--rightColor')
    @Input()
    rightColor: string = '#4683ee'

    value = false

    onChange: (value: boolean) => void
    onTouched: () => void

    registerOnChange(fn: (value: boolean) => void): void {
        this.onChange = fn
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn
    }

    writeValue(obj: boolean): void {
        this.value = obj
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled
    }

    toggleValue(): void {
        this.value = !this.value
        this.onChange(this.value)
        this.onTouched()
    }
}
