export enum ParamCodeEnum {
    footer_contact_mail = 'footer_contact_mail',
    footer_contact_mail_iris ='footer_contact_mail_iris',
    footer_contact_phone = 'footer_contact_phone',
    global_message = 'global_message',
    global_message_iris = 'global_message_iris',
    formation_maj_date = 'DATE_MAJ_CERTIFICATIONS',
    url_video_securite = 'VIDEO_ACCUEIL_SECURITE',
    url_video_securite_portugaise = 'VIDEO_ACCUEIL_SECURITE_PORTUG',
    date_validite_actualite = 'DATE_VALIDITE_ACTUALITE',
    date_validite_actualite_iris = 'DATE_VALIDITE_ACTUALITE_IRIS',
    wifi_court = 'CODE_WIFI_COURTE_DUREE',
    wifi_long = 'CODE_WIFI_LONGUE_DUREE',
    PORTLET_STAC_LIEN_APPLICATION = 'PORTLET_STAC_LIEN_APPLICATION',
    PORTLET_STAC_LIEN_DESCRIPTION = 'PORTLET_STAC_LIEN_DESCRIPTION',
    INFOS_ACCUEIL_CHANTIER = 'INFOS_ACCUEIL_CHANTIER',
    PORTLET_BOOSTERBOX_TEXTE = 'PORTLET_BOOSTERBOX_TEXTE',
}
