import { inject, Injectable } from '@angular/core'
import { ApplicationHttpService } from '../data-access/http/application-http.service'
import { combineLatestWith, filter, map, merge, Observable, Subject, takeUntil, tap } from 'rxjs'
import { ApplicationState } from '../data-access/state/application.state'
import { ChantierState } from '../../shared/data-access/state/chantier.state'
import { ApplicationDto } from '../data-access/http/dto/application.dto'
import { ApplicationCodeEnum } from '../../shared/models/enums/application-code-enum'
import { ApplicationInfoDto } from '../data-access/http/dto/application-info.dto'
import { ApplicationMapper } from './mappers/application.mapper'
import { Application } from '../../shared/models/application'
import { ChantierDTO } from '../../shared/data-access/http/dto/chantier-dto'
import { InfosPlanningLivraisonDto } from '../data-access/http/dto/infos-planning-livraison.dto'
import { DonneesRhPortletBodyComponent } from '../ui/portlet-body/donnees-rh-portlet-body/donnees-rh-portlet-body.component'
import { GestionDroitsPortletBodyComponent } from '../ui/portlet-body/gestion-droits-portlet-body/gestion-droits-portlet-body.component'
import { BoosterBoxPortletBodyComponent } from '../ui/portlet-body/booster-box-portlet-body/booster-box-portlet-body.component'
import { AffectationTachesPortletBodyComponent } from '../ui/portlet-body/affectation-taches-portlet-body/affectation-taches-portlet-body.component'
import { CatalogueServiceMaterielPortletBodyComponent } from '../ui/portlet-body/catalogue-service-materiel-portlet-body/catalogue-service-materiel-portlet-body.component'
import { WifiPortletBodyComponent } from '../ui/portlet-body/wifi-portlet-body/wifi-portlet-body.component'
import { ParamCodeEnum } from '../../shared/models/enums/param-code.enum'
import { StacPortletBodyComponent } from '../ui/portlet-body/stac-portlet-body/stac-portlet-body.component'
import { UserDomainService } from '../../shared/domain/user.domain'
import { ParamDomainService } from '../../shared/domain/param.domain'
import { Tab, tabData, TabSectionEnum } from '../data-access/constant/tab-data'
import { AccueilChantierPortletBodyComponent } from '../ui/portlet-body/accueil-chantier-portlet-body/accueil-chantier-portlet-body.component'

@Injectable({ providedIn: 'root' })
export class ApplicationDomainService {
    private applicationState = inject(ApplicationState)
    private applicationService = inject(ApplicationHttpService)
    private userDomainService = inject(UserDomainService)
    private paramDomainService = inject(ParamDomainService)

    private chantierState = inject(ChantierState)

    // Selectors
    readonly applications$: Observable<Application[]> = this.applicationState.store
        .select(ApplicationState.getApplications)
        .pipe(
            map(ApplicationMapper.mapApplicationDtosToApplications),
            map(ApplicationMapper.applyInfosFromSettings),
            combineLatestWith(
                this.applicationState.store.select(ApplicationState.getApplicationInfos),
                this.chantierState.store.select(ChantierState.getSelectedChantier),
            ),
            map(([applications, applicationInfos, selectedChantier]) => {
                const irisFilter = (app: Application) =>
                    selectedChantier.iris ? app.isIris || app.isSectionIris : app.isPortailChantier
                const onlyKeepVisibleApplications = (app: Application) => {
                    const appInfo = applicationInfos.find((appInfo) => appInfo.codeApplication === app.code)
                    return (appInfo && !appInfo.hidden) || (!appInfo && app.activeByDefault)
                }
                return applications
                    .filter(onlyKeepVisibleApplications)
                    .filter(irisFilter)
                    .map((app): Application => {
                        const appInfo = applicationInfos.find((appInfo) => appInfo.codeApplication === app.code)
                        const { infos, statut, highlighted } = appInfo || {}
                        return {
                            ...app,
                            bodyData: infos,
                            statut: statut || null,
                            activeLink: ApplicationDomainService.getActiveLink(app, appInfo),
                            actif: ApplicationDomainService.appIsActive(app, appInfo, selectedChantier),
                            highlighted,
                        }
                    })
            }),
        )

    readonly activeApplications$: Observable<Application[]> = this.applications$.pipe(
        map((applications) => applications.filter((app: Application) => app.actif && !app.isSectionIris)),
    )

    readonly inactiveApplications$: Observable<Application[]> = this.applications$.pipe(
        map((applications) => applications.filter((app: Application) => !app.actif)),
    )

    readonly sectionIrisApplications$: Observable<Application[]> = this.applications$.pipe(
        map((applications) => applications.filter((app: Application) => app.isSectionIris && app.actif)),
    )

    readonly selectedTab$: Observable<Tab> = this.applicationState.store
        .select(ApplicationState.getSelectedTab)
        .pipe(map((tabId) => tabData.find((tab) => tab.id === tabId)))

    readonly applicationsOutilsVisible$: Observable<Application[]> = this.applicationState.store
        .select(ApplicationState.getApplicationsOutils)
        .pipe(
            combineLatestWith(this.applicationState.store.select(ApplicationState.getSelectedTab)),
            map(([applications, selectedTab]) => applications.filter((app) => app.section === selectedTab)),
        )

    readonly applicationsOutilsAbove$: Observable<Application[]> = this.applicationState.store
        .select(ApplicationState.getApplicationsOutils)
        .pipe(map((applications) => applications.filter((app) => app.section === TabSectionEnum.Above)))

    readonly thereAreActiveApps$ = this.activeApplications$.pipe(map((activeApps) => activeApps.length > 0))
    readonly thereAreInactiveApps$ = this.inactiveApplications$.pipe(map((inactiveApps) => inactiveApps.length > 0))
    readonly thereAreSectionIrisApps$ = this.sectionIrisApplications$.pipe(
        map((sectionIrisApps) => sectionIrisApps.length > 0),
    )

    private readonly interruptCurrentHttpCalls$ = new Subject<string>()

    getApplicationByCode(applicationCode: ApplicationCodeEnum): Application {
        const applicationDtos = this.applicationState.store.selectSnapshot(ApplicationState.getApplications)
        const applicationInfoDtos = this.applicationState.store.selectSnapshot(ApplicationState.getApplicationInfos)
        const selectedChantier = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantier)

        const applications = ApplicationMapper.applyInfosFromSettings(
            ApplicationMapper.mapApplicationDtosToApplications(applicationDtos),
        )

        const application = applications.find((app) => app.code === applicationCode)
        const applicationInfo = applicationInfoDtos.find((appInfo) => appInfo.codeApplication === applicationCode)

        if (applicationInfo) {
            return {
                ...application,
                bodyData: applicationInfo.infos,
                statut: applicationInfo.statut || null,
                activeLink: ApplicationDomainService.getActiveLink(application, applicationInfo),
                actif: ApplicationDomainService.appIsActive(application, applicationInfo, selectedChantier),
                highlighted: applicationInfo.highlighted,
            }
        }
        return application
    }
    // Actions
    loadApplications(): Observable<ApplicationDto[]> {
        return this.applicationService.getApplications().pipe(
            tap((applications) =>
                this.applicationState.store.update((state) => ({
                    ...state,
                    applications,
                })),
            ),
        )
    }

    loadApplicationsData(): Observable<ApplicationInfoDto> {
        const chantierCode = this.chantierState.store.selectSnapshot(ChantierState.getSelectedChantierCode)
        const applications = this.applicationState.store.selectSnapshot(ApplicationState.getApplications)
        const getInfoObservables = applications.map((app) => this.applicationService.fetchData(app, chantierCode))
        this.interruptCurrentHttpCalls$.next('STOP CURRENT CALLS')
        this.applicationState.resetApplicationInfos()
        return merge(...getInfoObservables).pipe(
            takeUntil(this.interruptCurrentHttpCalls$),
            filter((appInfo) => !!appInfo),
            tap((appInfoDto) => {
                this.applicationState.pushApplicationInfo(appInfoDto)
            }),
        )
    }

    loadApplicationsOutils(): void {
        const donneesRhApp: Application = {
            name: 'Données RH des compagnons et des intérimaires',
            desc: 'Consultation des données RH',
            displayDescUnderTitle: true,
            activeLink: '/droitsrh',
            activeLinkText: 'Accéder à la recherche sans carte BTP',
            activeLinkParam: { noCarteBtp: true },
            code: ApplicationCodeEnum.DONNEES_RH,
            openLinksInNewTab: false,
            bodyComponent: DonneesRhPortletBodyComponent,
            doNotSendEvenement: true,
            logo: null,
            activeByDefault: true,
            highlighted: false,
            helpLink: null,
            section: TabSectionEnum.Above,
            actif: this.userDomainService.connectedUser().droitsRH,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const gestionDroitsApp: Application = {
            name: 'Gestion des droits',
            desc: 'Définir qui a accès aux applications pour ce chantier',
            displayDescUnderTitle: true,
            activeLink: this.userDomainService.userIsSuperAdminOrAdminChantier()
                ? 'https://pointagechantier.leon-grosse.fr/'
                : null,
            activeLinkText: "Accéder à l'application",
            code: ApplicationCodeEnum.GESTION_DROITS,
            doNotSendEvenement: true,
            bodyComponent: GestionDroitsPortletBodyComponent,
            logo: null,
            activeByDefault: true,
            openLinksInNewTab: true,
            highlighted: false,
            helpLink: null,
            section: TabSectionEnum.Communiquer,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const accueilSurChantierApp: Application = {
            code: ApplicationCodeEnum.ACCUEIL_CHANTIER,
            name: 'Accueil sur chantier',
            desc: "Accueil d'un nouvel arrivant sur chantier",
            activeLinkText: "Accéder à la page d'accueil",
            activeLink: '/accueil-chantier',
            logo: null,
            activeByDefault: true,
            displayDescUnderTitle: true,
            openLinksInNewTab: false,
            highlighted: false,
            helpLink: null,
            section: TabSectionEnum.Above,
            actif: this.userDomainService.connectedUser().droitsRH,
            bodyComponent: AccueilChantierPortletBodyComponent,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const boosterBoxApp: Application = {
            code: ApplicationCodeEnum.BOOSTER_BOX,
            name: 'Booster Box',
            desc: 'Des outils Google Léon Grosse pour faciliter le chantier',
            activeLinkText: 'Accéder à BoosterBox (LéonLive)',
            activeLink: 'https://leonlive.leongrosse.fr/home/booster-box',
            logo: 'assets/images/logos/BOOSTER_BOX.svg',
            activeByDefault: true,
            displayDescUnderTitle: true,
            openLinksInNewTab: true,
            highlighted: false,
            helpLink: null,
            bodyComponent: BoosterBoxPortletBodyComponent,
            section: TabSectionEnum.SuiviChantier,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: true,
        }

        const taskAssignmentApp: Application = {
            code: ApplicationCodeEnum.TASK_ASSIGNMENT,
            name: 'Affectation de tâches',
            desc: "Scannez le QR code pour accéder à l'application mobile",
            activeLinkText: "Accéder à l'application sur PC",
            activeLink: 'https://www.appsheet.com/start/24da4956-f560-447d-ae63-0468a75a95c7',
            logo: 'assets/images/logos/TASK_ASSIGNMENT.svg',
            activeByDefault: true,
            displayDescUnderTitle: true,
            openLinksInNewTab: true,
            highlighted: false,
            helpLink: 'https://drive.google.com/file/d/122YRDp7vCnwj2q-kH3qDQdQchXUwsPZu/view',
            bodyComponent: AffectationTachesPortletBodyComponent,
            section: TabSectionEnum.SuiviChantier,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const catalogueServiceApp: Application = {
            code: ApplicationCodeEnum.CATALOGUE_SERVICE_MATERIEL,
            name: 'Catalogue service matériel',
            desc: '',
            activeLinkText: 'Accéder au catalogue',
            activeLink: 'https://www.appsheet.com/start/221b84d1-ec07-459c-97c6-ded29c29208b',
            logo: 'assets/images/logos/CATALOGUE_SERVICE_MATERIEL.svg',
            activeByDefault: true,
            displayDescUnderTitle: true,
            openLinksInNewTab: true,
            highlighted: false,
            helpLink: null,
            bodyComponent: CatalogueServiceMaterielPortletBodyComponent,
            section: TabSectionEnum.CatalogueServiceMat,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const wifiApp: Application = {
            code: ApplicationCodeEnum.WIFI,
            activeByDefault: true,
            activeLinkText: '',
            displayDescUnderTitle: false,
            helpLink: '',
            highlighted: false,
            logo: 'assets/images/logos/WIFI.svg',
            name: 'Code wifi invité',
            openLinksInNewTab: false,
            bodyComponent: WifiPortletBodyComponent,
            section: TabSectionEnum.Above,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const stacApp: Application = {
            code: ApplicationCodeEnum.STAC,
            activeByDefault: true,
            activeLinkText: "Accéder à l'application sur PC",
            activeLink: this.paramDomainService.getParamByCode(ParamCodeEnum.PORTLET_STAC_LIEN_APPLICATION)
                ?.valeurParam,
            displayDescUnderTitle: true,
            helpLink: this.paramDomainService.getParamByCode(ParamCodeEnum.PORTLET_STAC_LIEN_DESCRIPTION)?.valeurParam,
            highlighted: false,
            logo: 'assets/images/logos/STAC.svg',
            name: 'STAC',
            openLinksInNewTab: true,
            bodyComponent: StacPortletBodyComponent,
            desc: 'Sous-traitant Alerte Chantier',
            section: TabSectionEnum.SuiviChantier,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const infoSecuApp: Application = {
            code: ApplicationCodeEnum.INFOS_SECURITE,
            activeByDefault: true,
            activeLinkText: "Accéder à l'application",
            activeLink:
                'https://leonlive.leongrosse.fr/home/search/Message%20pr%C3%A9vention?sort=-_metadata.updateTime',
            displayDescUnderTitle: true,
            helpLink: null,
            highlighted: false,
            logo: 'assets/images/logos/INFOS_SECURITE.svg',
            name: 'Informations Sécurité',
            openLinksInNewTab: true,
            desc: 'Message prévention de la semaine',
            section: TabSectionEnum.Formation,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const formInfoOutilsApp: Application = {
            code: ApplicationCodeEnum.FORM_OUTILS_INFO,
            activeByDefault: true,
            activeLinkText: "Accéder à l'application",
            activeLink:
                'https://leon-grosse-universite.360learning.com/search/learningItems?q=&learningitem=courses&learningitem=paths&learningitem=programSessions&learningitem=externalCourses&skill=644a97acc2744c65f7c33541&sortOrder=mostRecent',
            displayDescUnderTitle: true,
            helpLink: null,
            highlighted: false,
            logo: 'assets/images/logos/FORM_OUTILS_INFO.svg',
            name: 'FORMATION OUTILS INFORMATIQUES',
            openLinksInNewTab: true,
            desc: 'Vidéos de formation sur les outils informatiques des chantiers',
            section: TabSectionEnum.Formation,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }

        const mesIdeesApp: Application = {
            code: ApplicationCodeEnum.MES_IDEES,
            activeByDefault: true,
            activeLinkText: "Accéder à l'application",
            activeLink: 'https://forms.gle/RJxoz2taEYeEZ7sU7',
            displayDescUnderTitle: true,
            helpLink: null,
            highlighted: false,
            logo: 'assets/images/logos/MES_IDEES.svg',
            name: 'MES IDéES',
            openLinksInNewTab: true,
            desc: "Formulaire de remontée d'idées pour améliorer Portail Chantier",
            section: TabSectionEnum.Communiquer,
            actif: true,
            isIris: true,
            isPortailChantier: true,
            isSectionIris: false,
            disableLogoFilter: false,
        }
        this.applicationState.store.update((state) => ({
            ...state,
            applicationsOutils: [
                donneesRhApp,
                gestionDroitsApp,
                catalogueServiceApp,
                accueilSurChantierApp,
                boosterBoxApp,
                taskAssignmentApp,
                wifiApp,
                stacApp,
                infoSecuApp,
                formInfoOutilsApp,
                mesIdeesApp,
            ],
        }))
    }

    private static appIsActive(app: Application, appInfo: ApplicationInfoDto, selectedChantier: ChantierDTO): boolean {
        if (app.code === ApplicationCodeEnum.KAIRNIAL) {
            return selectedChantier.actifKairnial
        }
        return (appInfo && appInfo.actif) || app.activeByDefault
    }

    private static getActiveLink(app: Application, appInfo: ApplicationInfoDto): string {
        if (app.code === ApplicationCodeEnum.PLANNING_LIVRAISON_CHANTIER && appInfo) {
            const infosPlanningLivraisonDto = JSON.parse(appInfo.infos || '{}') as InfosPlanningLivraisonDto
            const gSheetId = infosPlanningLivraisonDto?.idGoogleSheetPLC
            return gSheetId ? `https://docs.google.com/spreadsheets/d/${gSheetId}/edit` : null
        }
        if (app.code === ApplicationCodeEnum.ACTIONS_BETON_LEON && appInfo) {
            return appInfo.highlighted ? app.activeLink : null
        }
        return app.activeLink
    }

    selectTab(tabId: TabSectionEnum): void {
        this.applicationState.store.update((state) => ({ ...state, selectedTab: tabId }))
    }
}
