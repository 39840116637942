export interface Tab {
    id: TabSectionEnum
    label: string
    color: string
}

export enum TabSectionEnum {
    SuiviChantier = 'suivi_chantier',
    CatalogueServiceMat = 'catalogue_service_mat',
    Formation = 'formation',
    Communiquer = 'communiquer',
    Above = 'above',
}

export const tabData: Tab[] = [
    {
        id: TabSectionEnum.SuiviChantier,
        label: 'Suivi de chantier',
        color: '#0292b7',
    },
    {
        id: TabSectionEnum.CatalogueServiceMat,
        label: 'Catalogue service mat',
        color: '#128f8b',
    },
    {
        id: TabSectionEnum.Formation,
        label: 'Formations',
        color: '#eeba2b',
    },

    {
        id: TabSectionEnum.Communiquer,
        label: 'Communiquer',
        color: '#a63950',
    },
]
