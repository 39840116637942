import { Component } from '@angular/core'
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-lg-not-found',
    templateUrl: './not-found.component.html',
    standalone: true,
    imports: [RouterLink],
})
export class NotFoundComponent {
    constructor() {
        // Nothing to see here
    }
}
