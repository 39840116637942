import { Component } from '@angular/core'
import { NgOptimizedImage } from '@angular/common'

@Component({
    selector: 'app-kairnial-portlet-body',
    standalone: true,
    imports: [NgOptimizedImage],
    templateUrl: './kairnial-portlet-body.component.html',
    styleUrl: './kairnial-portlet-body.component.scss',
})
export class KairnialPortletBodyComponent {
    protected readonly imgUrl = 'assets/images/home/kairnial-body.png'
}
