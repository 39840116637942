<div class="container py-0 px-0 h-100">
    <div class="h-100">
        <div *ngIf="status === applicationStatusEnum.KO" class="text-center h-100 pt-4">Aucune donnée trouvée</div>
        <ng-container *ngIf="status === applicationStatusEnum.OK && data">
            <div *ngIf="!data.accidents?.total" class="text-center pt-2">
                Bonne nouvelle, nous ne recensons pas d'accidents de travail pour votre chantier
            </div>
            <ng-container *ngIf="data.accidents?.total">
                <div class="row pb-3">
                    <div class="col-10" title="Jours sans accident">
                        Jours sans accident depuis le
                        <span class="fw-bold">{{ data.dateDernierAccident | date: "shortDate" }}</span>
                    </div>
                    <div class="col-2">
                        <div class="row ms-1">
                            <div class="fw-semibold text-nowrap ps-0 fs-6 text-end">
                                {{ data.nbJoursSansAccidents | number: "0.0-0" }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pb-3">
                    <div class="col-8" title="Accidents">Accidents</div>
                    <div class="col-4">
                        <div class="row ms-1">
                            <div class="fw-semibold text-nowrap ps-0 fs-6 text-end">
                                {{ data.accidents.total }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row h-100 my-3">
                    <div class="col-6">
                        <div class="chart-container">
                            <canvas #chart id="chart">{{ chart }}</canvas>
                        </div>
                    </div>
                    <div class="col-6 pt-3">
                        <div class="container px-0">
                            <div class="row my-3 fw-bold">
                                <div class="col-8 sans-arret">Sans arrêt</div>
                                <div class="col-4 text-end">{{ data.accidents.sansArret }}</div>
                            </div>
                            <div class="row my-3 fw-bold">
                                <div class="col-8 avec-arret">Avec arrêt</div>
                                <div class="col-4 text-end">{{ data.accidents.avecArret }}</div>
                            </div>
                            <div class="row my-3 fw-bold">
                                <div class="col-8 mortel">Mortel</div>
                                <div class="col-4 text-end">{{ data.accidents.mortel }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
