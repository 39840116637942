import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap'
import { DatePipe, DecimalPipe, NgFor, NgIf, SlicePipe } from '@angular/common'
import { DisplayDatePipe } from '../../../../shared/pipes/display-date.pipe'
import { ApplicationStatusEnum } from '../../../../shared/models/enums/application-status.enum'
import { parseCustomDate, removeTimeFromDateTime } from '../../../../shared/utils/date.utils'
import { At360EntitesInfosDto } from '../../../data-access/http/dto/at360-entites-infos.dto'
import { At360EntitesInfos } from '../../../../shared/models/at360-entites-infos'
import { Chart, ChartConfiguration, ChartOptions } from 'chart.js/auto'

@Component({
    selector: 'app-at360-entites-portlet-body',
    templateUrl: './at360-entites-portlet-body.component.html',
    styleUrls: ['./at360-entites-portlet-body.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgbPagination, SlicePipe, DatePipe, DisplayDatePipe, DecimalPipe],
})
export class At360EntitesPortletBodyComponent implements OnInit, AfterViewInit {
    data: At360EntitesInfos
    @ViewChild('chart')
    chartCanvas: ElementRef
    chart: Chart
    applicationStatusEnum = ApplicationStatusEnum
    constructor(
        @Inject('DATA') private dataInjected: string,
        @Inject('STATUS') public status: ApplicationStatusEnum,
    ) {}

    ngOnInit(): void {
        const at360EntitesDto: At360EntitesInfosDto = JSON.parse(this.dataInjected)
        this.data = At360EntitesPortletBodyComponent.mapDtoToModel(at360EntitesDto)
    }

    ngAfterViewInit(): void {
        if (this.data && this.data.accidents.total) {
            this.chart = this.buildChart(this.chartCanvas)
        }
    }

    // TODO: This method should not be in the component
    //  It should be performed in application domain but not easy right now
    private static mapDtoToModel(at360EntitesDto: At360EntitesInfosDto): At360EntitesInfos {
        if (!at360EntitesDto.codeEntite) {
            return null
        }
        const today = removeTimeFromDateTime(new Date())
        const {
            nomEntite,
            codeEntite,
            nbAccidentsMortels,
            nbAccidentsAvecArret,
            nbAccidentsSansArret,
            dateDernierAccident,
        } = at360EntitesDto
        return {
            entite: {
                nom: nomEntite,
                code: codeEntite,
            },
            dateDernierAccident: parseCustomDate(dateDernierAccident),
            nbJoursSansAccidents:
                (today.getTime() - (parseCustomDate(dateDernierAccident) || today).getTime()) / (1000 * 60 * 60 * 24),
            accidents: {
                total: nbAccidentsMortels + nbAccidentsAvecArret + nbAccidentsSansArret || 0,
                avecArret: nbAccidentsAvecArret || 0,
                sansArret: nbAccidentsSansArret || 0,
                mortel: nbAccidentsMortels || 0,
            },
        }
    }

    private buildChart(chartCanvasElement: ElementRef): Chart {
        const chartData = {
            labels: ['Mortel', 'Avec arrêt', 'Sans arrêt'],
            datasets: [
                {
                    label: 'Accidents',
                    data: [this.data.accidents.mortel, this.data.accidents.avecArret, this.data.accidents.sansArret],
                    backgroundColor: ['#E31B18', '#989898', '#5BB9FF'],
                    hoverOffset: 4,
                },
            ],
        }

        const chartOptions: ChartOptions = {
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
            },
        }

        const chartConfig: ChartConfiguration = { type: 'pie', data: chartData, options: chartOptions }
        return new Chart(chartCanvasElement.nativeElement.getContext('2d'), chartConfig)
    }
}
