import { Injectable } from '@angular/core'
import { ChantierDTO } from '../http/dto/chantier-dto'
import { Store } from '../../models/store'
import { ContactCsm } from '../../models/contact-csm'

export interface ChantierStateModel {
    selectedChantier: ChantierDTO
    selectedChantierAdmins: string[]
}

@Injectable({ providedIn: 'root' })
export class ChantierState {
    store = new Store<ChantierStateModel>({ selectedChantier: null, selectedChantierAdmins: null })

    //Selectors
    static getSelectedChantier = (state: ChantierStateModel): ChantierDTO => state.selectedChantier
    static getSelectedChantierCode = (state: ChantierStateModel): string => state?.selectedChantier?.code

    static getSelectedChantierPhotoUrl = (state: ChantierStateModel): string =>
        state?.selectedChantier?.urlPhotoChantier
    static getSelectedChantierAdmins = (state: ChantierStateModel): string[] => state?.selectedChantierAdmins
    static getSelectedChantierCSM = (state: ChantierStateModel): ContactCsm => ({
        nom: state?.selectedChantier?.nomCSM,
        telephone: state?.selectedChantier?.telephoneCSM,
    })
}
