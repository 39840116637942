import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'carteBtpFormat',
    standalone: true,
})
export class CarteBtpFormatPipe implements PipeTransform {
    transform(carteBtp: string): string {
        if (!carteBtp) {
            return ''
        }
        const chunks = carteBtp.replace(/\s/g, '').match(/.{1,3}/g)
        if (chunks) {
            return chunks.join(' ')
        }
        return carteBtp
    }
}
