import { Component, EventEmitter, Input, Output } from '@angular/core'
import { NgIf } from '@angular/common'
import { User } from '../../../../shared/models/user'
import { RouterLink } from '@angular/router'

@Component({
    selector: 'app-user-widget',
    standalone: true,
    imports: [NgIf, RouterLink],
    templateUrl: './user-widget.component.html',
    styleUrl: './user-widget.component.scss',
})
export class UserWidgetComponent {
    @Input({ required: true })
    user: User

    @Output()
    logout = new EventEmitter<void>()

    logOut(): void {
        this.logout.emit()
    }
}
